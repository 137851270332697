import { Grid, IconButton, Tooltip, Typography } from '@material-ui/core'
import { VideoPlayerWithPictureSnapshot } from '../common/video/VideoPlayerWithPictureSnapshot'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { getRaceHistoricalVideo, Video } from '../../services/get_race_video_url'
import { getErrorData } from '../../services/get_error'
import { Dispatch } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { Race } from '../../models/race'
import getRacePublicTimeMarker, { getRunnerPublicTimeMarkers } from '../../services/get_race_public_time_marker'
import {
    GenericHistoricalTracker,
    GenericHistoricalTrackerTimedMessage,
} from '../../services/generic_historical_tracker'
import { Refresh } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { getPictureVideoURL } from '../../services/get_picture_video_url'
import { createRaceState } from '../../services/create_race_state'
import { RootStateType } from '../../store'
import { ScrubLocation } from '../common/video/VideoControls'

const useStyles = makeStyles((theme) => ({
    '@keyframes blinker': {
        from: { opacity: 1 },
        to: { opacity: 0 },
    },
    alert: {
        animationName: '$blinker',
        animationDuration: '.5s',
        animationTimingFunction: 'linear',
        animationIterationCount: 'infinite',
    },
    outline: {
        border: 'solid',
        borderColor: 'red',
        borderWidth: '5px',
    },
}))

interface FullHistoricalVideoPlayerProps {
    race: Race
    videoSource: string
    onProgress: (timestamp: number) => void

    onVideoNotFound: () => void
    onPublicTimeMarkerNotFound: () => void

    tracker: GenericHistoricalTracker

    startedTracking: boolean
    allowVideoControls: boolean
}

export const HistoricalTrackerVideoController = (props: FullHistoricalVideoPlayerProps) => {
    const [url, setUrl] = useState(null as string | null)
    const [format, setFormat] = useState(null as string | null)
    const [videoSnapURL, setVideoSnapURL] = useState('')
    const [hasDisplayedMessages, setHasDisplayedMessages] = useState(false)
    const [currentTimestamp, setCurrentTimestamp] = useState(null as number | null)
    const [videoStartTime, setVideoStartTime] = useState(null as Date | null)
    const [shouldFetchVideo, setShouldFetchVideo] = useState(true)
    const programNumberScrubLocations = useProgramNumberScrubLocations(props.race, props.tracker, videoStartTime)
    const dispatch = useDispatch()

    useEffect(() => {
        const fetchPlaceholder = async () => {
            const placeholder = (await getPictureVideoURL('/LDE_EXAMPLES/PLACEHOLDER.png')) as string
            setVideoSnapURL(placeholder)
        }
        if (props.tracker.TimedMessages.length > 0 && !props.tracker.AllowVideoControls) {
            fetchPlaceholder()
        }
    }, [props.race.id, props.tracker.TimedMessages.length, props.tracker.AllowVideoControls])

    useEffect(() => {
        const processMessage = (event: MessageEvent) => {
            if (!event.data.videoSnapURL || !event.data.raceKey) {
                return
            }
            if (event.data.raceKey !== `${props.race.raceDate}-${props.race.trackCode}-${props.race.raceNumber}`) {
                return
            }
            setVideoSnapURL(event.data.videoSnapURL)
        }
        if (props.tracker.TimedMessages.length > 0 && !props.tracker.AllowVideoControls) {
            window.addEventListener('message', processMessage)
        }
        return () => window.removeEventListener('message', processMessage)
    }, [props.race.id, props.tracker.TimedMessages.length, props.tracker.AllowVideoControls])

    const latency = useSelector((rootState: RootStateType) => rootState?.latencyStore?.latencyInSeconds ?? 0)

    const onDisplayedMessageChanged = async (isDisplayed: boolean) => {
        if (isDisplayed) {
            const videoBoundingRect = document?.getElementById(`${props.race.raceKey()}-video`)?.getBoundingClientRect()
            let x, y, width, height
            if (videoBoundingRect !== undefined) {
                x = videoBoundingRect.x / window.innerWidth
                y = videoBoundingRect.y / window.innerHeight
                width = videoBoundingRect.width / window.innerWidth
                height = videoBoundingRect.height / window.innerHeight
            }

            const type = 'START_TIMED_MESSAGE_DISPLAY'
            await createRaceState(
                {
                    raceDate: props.race.raceDate,
                    trackCode: props.race.trackCode,
                    raceNumber: props.race.raceNumber,
                    type: type,
                    latencyInSeconds: latency,
                    practiceMode: props.tracker.PracticeMode,
                    genericHistoricalTrackerID: props.tracker.ID,
                    x: x,
                    y: y,
                    width: width,
                    height: height,
                },
                dispatch
            )
        }
    }

    useEffect(() => {
        setShouldFetchVideo(true)
    }, [props.race.raceDate, props.race.trackCode, props.race.raceNumber, props.videoSource])

    useEffect(() => {
        if (shouldFetchVideo) {
            setShouldFetchVideo(false)
            fetchVideoUrl(
                props.race,
                dispatch,
                props.onVideoNotFound,
                props.onPublicTimeMarkerNotFound,
                props.tracker,
                props.videoSource,
                setUrl,
                setVideoStartTime,
                setCurrentTimestamp,
                setFormat
            )
        }
    }, [
        shouldFetchVideo,
        dispatch,
        props.race,
        props.onVideoNotFound,
        props.onPublicTimeMarkerNotFound,
        props.tracker,
        props.videoSource,
    ])

    const classes = useStyles()

    return (
        <>
            <Grid item>
                <Grid container direction={'column'} justifyContent={'center'} alignItems={'center'}>
                    <Grid item>
                        <Grid container direction={'row'} spacing={1} alignItems={'center'} justifyContent={'center'}>
                            <Grid item>
                                <Tooltip title={'Reload video'}>
                                    <IconButton onClick={() => setShouldFetchVideo(true)}>
                                        <Refresh />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                {currentTimestamp && videoStartTime && (
                                    <TimedMessages
                                        race={props.race}
                                        timedMessages={props.tracker.TimedMessages}
                                        videoTimestamp={currentTimestamp}
                                        setHasDisplayedMessages={(newHasDisplayedMessages) => {
                                            if (newHasDisplayedMessages !== hasDisplayedMessages) {
                                                setHasDisplayedMessages(newHasDisplayedMessages)
                                                onDisplayedMessageChanged(newHasDisplayedMessages)
                                            }
                                        }}
                                        videoStartTime={videoStartTime}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={hasDisplayedMessages ? classes.outline : ''}>
                        {url && (
                            <VideoPlayerWithPictureSnapshot
                                url={url}
                                historical={true}
                                disableControls={!props.allowVideoControls}
                                pictureURL={videoSnapURL}
                                play={props.allowVideoControls ? undefined : props.startedTracking}
                                onProgress={(event: any) => {
                                    setCurrentTimestamp(event.currentTarget.currentTime)
                                    props.onProgress(event.currentTarget.currentTime)
                                }}
                                idForIFrame={`${props.race.raceKey()}-video`}
                                scrubLocations={programNumberScrubLocations}
                                height={600}
                                width={800}
                                format={format ?? 'mp4'}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

interface TimedMessagesProps {
    race: Race
    timedMessages: GenericHistoricalTrackerTimedMessage[]
    videoTimestamp: number
    videoStartTime: Date
    setHasDisplayedMessages: (b: boolean) => void
}

const TimedMessages = ({
    race,
    timedMessages,
    videoTimestamp,
    videoStartTime,
    setHasDisplayedMessages,
}: TimedMessagesProps) => {
    const [messagesAreDisplayed, setMessagesAreDisplayed] = useState(new Set<string>())
    useEffect(() => {
        setMessagesAreDisplayed(new Set<string>())
    }, [race.id, timedMessages])

    useEffect(() => {
        setHasDisplayedMessages(messagesAreDisplayed.size > 0)
    }, [messagesAreDisplayed, setHasDisplayedMessages])
    return (
        <Grid container direction={'column'} spacing={1} justifyContent={'center'} alignItems={'center'}>
            {timedMessages.map((message) => (
                <TimedMessage
                    race={race}
                    timedMessage={message}
                    videoTimestamp={videoTimestamp}
                    videoStartTime={videoStartTime}
                    setIsDisplayed={(isDisplayed: boolean) => {
                        if (isDisplayed && !messagesAreDisplayed.has(message.Message)) {
                            const newMessagesAreDisplayed = new Set([...messagesAreDisplayed, message.Message])
                            setMessagesAreDisplayed(newMessagesAreDisplayed)
                        }
                        if (!isDisplayed && messagesAreDisplayed.has(message.Message)) {
                            const newMessagesAreDisplayed = new Set([...messagesAreDisplayed])
                            newMessagesAreDisplayed.delete(message.Message)
                            setMessagesAreDisplayed(newMessagesAreDisplayed)
                        }
                    }}
                    key={message.Message}
                />
            ))}
        </Grid>
    )
}

const useProgramNumberScrubLocations = (race: Race, tracker: GenericHistoricalTracker, videoStartTime: Date | null) => {
    const [programNumberScrubLocations, setProgramNumberScrubLocations] = useState([] as ScrubLocation[])
    useEffect(() => {
        const fetchTimeMarkers = async () => {
            const timeMarkers = await getRunnerPublicTimeMarkers(
                race.raceDate,
                race.trackCode,
                race.raceNumber,
                tracker.ID
            )
            const scrubLocations = timeMarkers.map((timeMarker) => {
                if (timeMarker.videoTimestamp > 0) {
                    return {
                        timestamp: timeMarker.videoTimestamp,
                        timestampName: `runner ${timeMarker.programNumber}`,
                    }
                }
                return {
                    timestamp: (timeMarker.time.getTime() - videoStartTime!.getTime()) / 1000 - 3,
                    timestampName: `runner ${timeMarker.programNumber}`,
                }
            })
            setProgramNumberScrubLocations(scrubLocations)
        }
        if (!tracker.AllowJumpToHorseTimestamps || !videoStartTime) {
            return
        }
        fetchTimeMarkers()
    }, [race, tracker, videoStartTime])
    return programNumberScrubLocations
}

const TimedMessage = ({
    race,
    timedMessage,
    videoTimestamp,
    videoStartTime,
    setIsDisplayed,
}: {
    race: Race
    timedMessage: GenericHistoricalTrackerTimedMessage
    videoTimestamp: number
    videoStartTime: Date
    setIsDisplayed: (b: boolean) => void
}) => {
    const [startTimestamp, setStartTimestamp] = useState(null as number | null)
    const [endTimestamp, setEndTimestamp] = useState(null as number | null)
    const dispatch = useDispatch()
    useEffect(() => {
        const setStartEndTimestamp = async () => {
            try {
                const { time, videoTimestamp } = await getRacePublicTimeMarker(
                    race.raceDate,
                    race.trackCode,
                    race.raceNumber,
                    timedMessage.RelativeRaceState
                )
                const videoTimestampToUse = videoTimestamp ?? (time.getTime() - videoStartTime.getTime()) / 1000
                setStartTimestamp(videoTimestampToUse + timedMessage.OffsetSeconds)
                setEndTimestamp(videoTimestampToUse + timedMessage.OffsetSeconds + timedMessage.DurationSeconds)
            } catch (e) {
                setStartTimestamp(null)
                setEndTimestamp(null)
                getErrorData(e, dispatch)
            }
        }
        setStartEndTimestamp()
    }, [
        videoStartTime,
        race.raceDate,
        race.trackCode,
        race.raceNumber,
        timedMessage.RelativeRaceState,
        timedMessage.Message,
        timedMessage.OffsetSeconds,
        timedMessage.DurationSeconds,
        dispatch,
    ])

    useEffect(() => {
        if (
            !videoTimestamp ||
            !startTimestamp ||
            !endTimestamp ||
            videoTimestamp < startTimestamp ||
            videoTimestamp > endTimestamp
        ) {
            setIsDisplayed(false)
        } else {
            setIsDisplayed(true)
        }
    }, [startTimestamp, endTimestamp, videoTimestamp, setIsDisplayed])

    const classes = useStyles()

    if (!startTimestamp || !endTimestamp) {
        return null
    }

    if (!videoTimestamp || videoTimestamp < startTimestamp || videoTimestamp > endTimestamp) {
        return null
    }

    return (
        <Grid item>
            <Typography variant={'h6'} color={'error'} className={classes.alert}>
                {timedMessage.Message}
            </Typography>
        </Grid>
    )
}

const fetchVideoUrl = async (
    race: Race,
    dispatch: Dispatch<any>,
    onVideoNotFound: () => void,
    onPublicTimeMarkerNotFound: () => void,
    tracker: GenericHistoricalTracker,
    videoSource: string,
    setUrl: (url: string | null) => void,
    setVideoStartTime: (timestamp: Date | null) => void,
    setCurrentTimestamp: (timestamp: number | null) => void,
    setFormat: (timestamp: string | null) => void
) => {
    let video: void | Video | undefined
    try {
        video = await getRaceHistoricalVideo(
            race.trackCode,
            parseInt(race.raceDate),
            race.raceNumber,
            videoSource,
            () => {}
        )
        if (!video) {
            throw new Error('Did not find video')
        }
    } catch (err) {
        getErrorData(err, dispatch)
        onVideoNotFound()
        return
    }

    let publicTimeMarker: Date
    let publicTimeMarkerVideoTimestamp: number | null
    try {
        if (!tracker.UseActualPostTimeAsPublicTimeMarker) {
            const response = await getRacePublicTimeMarker(
                race.raceDate,
                race.trackCode,
                race.raceNumber,
                tracker.VideoStartPublicTimeMarker
            )
            publicTimeMarker = response.time
            publicTimeMarkerVideoTimestamp = response.videoTimestamp
        } else {
            publicTimeMarker = race.actualPostTime
            publicTimeMarkerVideoTimestamp = null
        }
    } catch (err) {
        getErrorData(err, dispatch)
        onPublicTimeMarkerNotFound()
        return
    }

    const videoStartTime = Date.parse(video.StartTime)
    let videoTimestamp: number
    if (publicTimeMarkerVideoTimestamp !== null) {
        videoTimestamp = publicTimeMarkerVideoTimestamp
    } else {
        videoTimestamp = (publicTimeMarker.getTime() - videoStartTime) / 1000 + tracker.VideoStartOffsetSeconds
    }
    const url = `${video.URL}#t=${videoTimestamp}`
    setUrl(url)
    setFormat(video.Format)
    setCurrentTimestamp(videoTimestamp)
    setVideoStartTime(new Date(video.StartTime))
}
