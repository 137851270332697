import axios from 'axios'
import { getErrorData } from './get_error'
import { userService } from './login'
import { v4 as uuidv4 } from 'uuid'
import { Dispatch } from 'react'

export interface RunnerEventCreationConfig {
    apiURLOverride?: string
    raceDate: string
    trackCode: string
    raceNumber: number
    programNumber: string
    eventType: string
    videoTimestamp: number | null
    practiceMode: boolean
    videoSource: string
    genericHistoricalTrackerID?: number | null
    genericRealTimeTrackerID?: number | null
}

export function addRunnerEvent(config: RunnerEventCreationConfig, dispatch: Dispatch<any>) {
    const uuidString = uuidv4().toString()
    const baseURL = config.apiURLOverride ?? ''
    const url = `${baseURL}/api/v2/runner_events`

    // eslint-disable-next-line no-restricted-globals
    parent.postMessage(
        {
            type: 'runnerStateEvent',
            raceStateType: config.eventType,
            raceKey: `${config.raceDate}-${config.trackCode}-${config.raceNumber}`,
            uuid: uuidString,
        },
        '*'
    )

    const request = axios
        .post(
            url,
            {
                race_date: config.raceDate,
                track_code: config.trackCode,
                race_number: config.raceNumber,
                program_number: config.programNumber,
                type: config.eventType,
                video_timestamp: config.videoTimestamp,
                video_source: config.videoSource,
                s3_uuid: uuidString,
                practice_mode: config.practiceMode,
                generic_historical_tracker_id: config.genericHistoricalTrackerID,
                generic_real_time_tracker_id: config.genericRealTimeTrackerID,
            },
            {
                headers: userService.authHeader(),
                timeout: 1000 * 30,
            }
        )
        .catch((error) => {
            getErrorData(error, dispatch)
        })

    return request
}
