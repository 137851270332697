import axios from 'axios'
import { getErrorData } from './get_error'
import { userService } from './login'
import { RaceStateTypes } from '../types'
import { v4 as uuidv4 } from 'uuid'
import { Dispatch } from 'react'

export interface RaceStateCreationConfig {
    apiURLOverride?: string
    raceDate: string
    trackCode: string
    raceNumber: number
    type: RaceStateTypes | string
    latencyInSeconds: number
    practiceMode: boolean
    videoTimestamp?: number | null
    videoSource?: string | null
    genericHistoricalTrackerID?: number | null
    genericRealTimeTrackerID?: number | null
    x?: number
    y?: number
    width?: number
    height?: number
}

export function createRaceState(config: RaceStateCreationConfig, dispatch: Dispatch<any>) {
    const baseURL = config.apiURLOverride ?? ''
    const url = `${baseURL}/api/v2/race_state`

    const uuidString = uuidv4().toString()

    // eslint-disable-next-line no-restricted-globals
    parent.postMessage(
        {
            type: 'raceStateEvent',
            raceStateType: config.type,
            raceKey: `${config.raceDate}-${config.trackCode}-${config.raceNumber}`,
            uuid: uuidString,
            x: config.x,
            y: config.y,
            width: config.width,
            height: config.height,
        },
        '*'
    )

    return axios
        .post(
            url,
            {
                raceDate: config.raceDate,
                trackCode: config.trackCode,
                raceNumber: config.raceNumber,
                type: config.type.toString(),
                uuid: uuidString,
                latencyInSeconds: config.latencyInSeconds,
                videoTimestamp: config.videoTimestamp ?? null,
                videoSource: config.videoSource ?? null,
                practiceMode: config.practiceMode,
                genericHistoricalTrackerID: config.genericHistoricalTrackerID,
                genericRealTimeTrackerID: config.genericRealTimeTrackerID,
            },
            {
                headers: userService.authHeader(),
                timeout: 1000 * 10,
            }
        )
        .catch((error) => {
            if (axios.isAxiosError(error) && error.response) {
                getErrorData(error, dispatch)
            }
        })
}
