import React, { useEffect, useState } from 'react'
import { Race } from '../../models/race'
import { Button, Divider, Grid, Tooltip } from '@material-ui/core'
import {
    GenericHistoricalTracker,
    GenericHistoricalTrackerRaceStateGroup,
} from '../../services/generic_historical_tracker'
import { createRaceState } from '../../services/create_race_state'
import { ButtonWithConfirmation } from '../generic_real_time_tracker/RaceCard'
import { GenericRealTimeTrackerRaceState } from '../../services/generic_real_time_tracker'
import { RootStateType } from '../../store'
import { useDispatch, useSelector } from 'react-redux'
import { hasRaceState } from '../generic_real_time_tracker/RaceButtons'

export interface GenericHistoricalTrackerRaceButtonsProps {
    Tracker: GenericHistoricalTracker
    Race: Race
    VideoTimestamp: number | null
    VideoSource: string
    OnTrackingCompleted: () => void
    UpdateRace: () => void
}

export const GenericHistoricalTrackerRaceButtons = ({
    Tracker,
    Race,
    OnTrackingCompleted,
    VideoTimestamp,
    VideoSource,
    UpdateRace,
}: GenericHistoricalTrackerRaceButtonsProps) => {
    const buttonColumns = [
        ...new Set(
            Tracker.GenericHistoricalTrackerRaceStateGroups.filter((g) => g.ButtonColumn > 0).map((g) => g.ButtonColumn)
        ),
    ]
    return (
        <>
            <Grid container direction={'row'} spacing={1} justifyContent={'center'} alignItems={'center'}>
                {buttonColumns.map((bc) => {
                    return (
                        <Grid item style={{ order: bc }} key={bc}>
                            <Grid
                                container
                                direction={'column'}
                                spacing={1}
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                {Tracker.GenericHistoricalTrackerRaceStateGroups.filter(
                                    (rsg) =>
                                        (rsg.RaceType === null || rsg.RaceType === Race.raceType) &&
                                        (rsg.SubRaceType === null || rsg.SubRaceType === Race.raceSubType) &&
                                        (rsg.StartType === null || rsg.StartType === Race.startType) &&
                                        rsg.ButtonColumn === bc
                                )
                                    .sort((a, b) => (a.ButtonColumnOrder ?? 0) - (b.ButtonColumnOrder ?? 0))
                                    .map((rsg) => (
                                        <React.Fragment key={rsg.Name}>
                                            <Grid item>
                                                <Divider variant={'middle'} />
                                            </Grid>
                                            <Grid item>
                                                {!rsg.IsOptionsGroup && (
                                                    <SequentialRaceStateButtonGroup
                                                        ButtonGroup={rsg}
                                                        Race={Race}
                                                        OnTrackingCompleted={OnTrackingCompleted}
                                                        VideoTimestamp={VideoTimestamp}
                                                        VideoSource={VideoSource}
                                                        PracticeMode={Tracker.PracticeMode}
                                                        TrackerID={Tracker.ID}
                                                        UpdateRace={UpdateRace}
                                                    />
                                                )}
                                                {rsg.IsOptionsGroup && (
                                                    <OptionsRaceStateButtonGroup
                                                        ButtonGroup={rsg}
                                                        Race={Race}
                                                        OnTrackingCompleted={OnTrackingCompleted}
                                                        VideoTimestamp={VideoTimestamp}
                                                        VideoSource={VideoSource}
                                                        PracticeMode={Tracker.PracticeMode}
                                                        TrackerID={Tracker.ID}
                                                        UpdateRace={UpdateRace}
                                                    />
                                                )}
                                            </Grid>
                                        </React.Fragment>
                                    ))}
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
        </>
    )
}

interface RaceStateButtonGroupProps {
    ButtonGroup: GenericHistoricalTrackerRaceStateGroup
    Race: Race
    OnTrackingCompleted: () => void
    UpdateRace: () => void
    VideoTimestamp: number | null
    VideoSource: string
    PracticeMode: boolean
    TrackerID: number
}

const SequentialRaceStateButtonGroup = ({
    ButtonGroup,
    Race,
    OnTrackingCompleted,
    VideoTimestamp,
    VideoSource,
    PracticeMode,
    TrackerID,
    UpdateRace,
}: RaceStateButtonGroupProps) => {
    const [order, setOrder] = useState(1)
    const latency = useSelector((state: RootStateType) => state?.latencyStore?.latencyInSeconds ?? 0)
    const dispatch = useDispatch()
    useEffect(() => {
        const clickedButtons = ButtonGroup.GenericHistoricalTrackerRaceStates.filter((b) =>
            hasRaceState(Race.raceStates, b.RaceState)
        )
        const calculatedOrder = clickedButtons?.length > 0 ? Math.max(...clickedButtons.map((b) => b.Order)) + 1 : 1
        setOrder(calculatedOrder)
    }, [Race, ButtonGroup])
    const buttons = ButtonGroup.GenericHistoricalTrackerRaceStates.filter((b) => b.Order === order).sort(
        (a, b) => a.HorizontalOrder - b.HorizontalOrder
    )

    if (buttons.length === 0) {
        return null
    }

    return (
        <Grid container direction={'row'} spacing={1}>
            {buttons.map((b) => {
                return (
                    <Grid item key={b.RaceState}>
                        <Tooltip title={b.Explanation}>
                            <>
                                {b.RequireConfirmation && (
                                    <ButtonWithConfirmation
                                        buttonText={b.ButtonText}
                                        buttonColor={b.IsSecondaryButton ? 'secondary' : 'primary'}
                                        onConfirmed={async () => {
                                            const emitRaceStates = async (
                                                buttonToSubmit: GenericRealTimeTrackerRaceState
                                            ) => {
                                                await createRaceState(
                                                    {
                                                        raceDate: Race.raceDate,
                                                        trackCode: Race.trackCode,
                                                        raceNumber: Race.raceNumber,
                                                        type: buttonToSubmit.RaceState,
                                                        latencyInSeconds: latency,
                                                        practiceMode: PracticeMode,
                                                        videoTimestamp: VideoTimestamp,
                                                        videoSource: VideoSource,
                                                        genericHistoricalTrackerID: TrackerID,
                                                        genericRealTimeTrackerID: null,
                                                    },
                                                    dispatch
                                                )
                                                if (buttonToSubmit.IsStopTracking) {
                                                    OnTrackingCompleted()
                                                } else {
                                                    UpdateRace()
                                                }
                                            }
                                            emitRaceStates(b)
                                            setOrder(order + 1)
                                        }}
                                    />
                                )}
                                {!b.RequireConfirmation && (
                                    <Button
                                        color={b.IsSecondaryButton ? 'secondary' : 'primary'}
                                        variant="contained"
                                        size={'small'}
                                        onClick={() => {
                                            const emitRaceStates = async (
                                                buttonToSubmit: GenericRealTimeTrackerRaceState
                                            ) => {
                                                await createRaceState(
                                                    {
                                                        raceDate: Race.raceDate,
                                                        trackCode: Race.trackCode,
                                                        raceNumber: Race.raceNumber,
                                                        type: buttonToSubmit.RaceState,
                                                        latencyInSeconds: latency,
                                                        practiceMode: PracticeMode,
                                                        videoTimestamp: VideoTimestamp,
                                                        videoSource: VideoSource,
                                                        genericHistoricalTrackerID: TrackerID,
                                                        genericRealTimeTrackerID: null,
                                                    },
                                                    dispatch
                                                )
                                                if (buttonToSubmit.IsStopTracking) {
                                                    OnTrackingCompleted()
                                                } else {
                                                    UpdateRace()
                                                }
                                            }
                                            emitRaceStates(b)
                                            setOrder(order + 1)
                                        }}
                                    >
                                        {b.ButtonText}
                                    </Button>
                                )}
                            </>
                        </Tooltip>
                    </Grid>
                )
            })}
        </Grid>
    )
}

const OptionsRaceStateButtonGroup = ({
    ButtonGroup,
    Race,
    OnTrackingCompleted,
    VideoTimestamp,
    VideoSource,
    PracticeMode,
    TrackerID,
    UpdateRace,
}: RaceStateButtonGroupProps) => {
    const latency = useSelector((state: RootStateType) => state?.latencyStore?.latencyInSeconds ?? 0)
    const dispatch = useDispatch()
    return (
        <>
            <Grid container direction={'row'} spacing={1}>
                {ButtonGroup.GenericHistoricalTrackerRaceStates.sort((a, b) => a.Order - b.Order).map((button) => {
                    const hasClickedButton = hasRaceState(Race.raceStates, button.RaceState)
                    return (
                        <Grid item key={button.RaceState}>
                            <Tooltip title={button.Explanation}>
                                <>
                                    {button.RequireConfirmation && (
                                        <ButtonWithConfirmation
                                            buttonText={button.ButtonText}
                                            buttonColor={button.IsSecondaryButton ? 'secondary' : 'primary'}
                                            onConfirmed={async () => {
                                                const emitRaceStates = async (
                                                    buttonToSubmit: GenericRealTimeTrackerRaceState
                                                ) => {
                                                    await createRaceState(
                                                        {
                                                            raceDate: Race.raceDate,
                                                            trackCode: Race.trackCode,
                                                            raceNumber: Race.raceNumber,
                                                            type: buttonToSubmit.RaceState,
                                                            latencyInSeconds: latency,
                                                            practiceMode: PracticeMode,
                                                            videoTimestamp: VideoTimestamp,
                                                            videoSource: VideoSource,
                                                            genericHistoricalTrackerID: TrackerID,
                                                            genericRealTimeTrackerID: null,
                                                        },
                                                        dispatch
                                                    )
                                                    if (buttonToSubmit.IsStopTracking) {
                                                        OnTrackingCompleted()
                                                    } else {
                                                        UpdateRace()
                                                    }
                                                }
                                                emitRaceStates(button)
                                            }}
                                        />
                                    )}
                                    {!button.RequireConfirmation && (
                                        <Button
                                            color={button.IsSecondaryButton ? 'secondary' : 'primary'}
                                            variant="contained"
                                            size={'small'}
                                            disabled={hasClickedButton}
                                            onClick={() => {
                                                const emitRaceStates = async (
                                                    buttonToSubmit: GenericRealTimeTrackerRaceState
                                                ) => {
                                                    await createRaceState(
                                                        {
                                                            raceDate: Race.raceDate,
                                                            trackCode: Race.trackCode,
                                                            raceNumber: Race.raceNumber,
                                                            type: buttonToSubmit.RaceState,
                                                            latencyInSeconds: latency,
                                                            practiceMode: PracticeMode,
                                                            videoTimestamp: VideoTimestamp,
                                                            videoSource: VideoSource,
                                                            genericHistoricalTrackerID: TrackerID,
                                                            genericRealTimeTrackerID: null,
                                                        },
                                                        dispatch
                                                    )
                                                    if (buttonToSubmit.IsStopTracking) {
                                                        OnTrackingCompleted()
                                                    } else {
                                                        UpdateRace()
                                                    }
                                                }
                                                emitRaceStates(button)
                                            }}
                                        >
                                            {button.ButtonText}
                                        </Button>
                                    )}
                                </>
                            </Tooltip>
                        </Grid>
                    )
                })}
            </Grid>
        </>
    )
}
