import { makeStyles } from '@material-ui/core/styles'
import { Race } from '../../models/race'
import { GenericRealTimeTracker, GenericRealTimeTrackerTimedMessage } from '../../services/generic_real_time_tracker'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getRaceLiveVideoUrl } from '../../services/get_race_video_url'
import { getPictureVideoURL } from '../../services/get_picture_video_url'
import { RootStateType } from '../../store'
import { createRaceState } from '../../services/create_race_state'
import { Grid, Typography } from '@material-ui/core'
import { VideoPlayerWithPictureSnapshot } from '../common/video/VideoPlayerWithPictureSnapshot'
import { getSecondsToPostEstimate } from '../../services/get_seconds_to_post_estimate'
import { getErrorData } from '../../services/get_error'

const useStylesRaceVideo = makeStyles((theme) => ({
    '@keyframes blinker': {
        from: { opacity: 1 },
        to: { opacity: 0 },
    },
    alert: {
        animationName: '$blinker',
        animationDuration: '.5s',
        animationTimingFunction: 'linear',
        animationIterationCount: 'infinite',
    },
    outline: {
        border: 'solid',
        borderColor: 'red',
        borderWidth: '5px',
    },
    invisibleOutline: {
        border: 'solid',
        borderColor: 'transparent',
        borderWidth: '5px',
    },
    h6Height: {
        minHeight: '2.1rem',
    },
}))

export const RealTimeTrackerVideoController = ({
    race,
    tracker,
    videoSource,
}: {
    race: Race
    tracker: GenericRealTimeTracker
    videoSource: string
}) => {
    const [videoUrl, setVideoUrl] = useState('')
    const [videoFormat, setVideoFormat] = useState('')
    const [videoSnapURL, setVideoSnapURL] = useState('')
    const [displayedMessage, setDisplayedMessage] = useState('')
    const dispatch = useDispatch()
    useEffect(() => {
        const fetchVideo = async () => {
            const videoResponse = await getRaceLiveVideoUrl(race.trackCode, videoSource, dispatch)
            if (!videoResponse) {
                setTimeout(fetchVideo, 10_000)
                return
            }
            setVideoUrl(videoResponse?.url ?? '')
            setVideoFormat(videoResponse?.format ?? '')
        }
        fetchVideo()
    }, [race.trackCode, dispatch, videoSource])

    useEffect(() => {
        const fetchPlaceholder = async () => {
            const placeholder = (await getPictureVideoURL('/LDE_EXAMPLES/PLACEHOLDER.png')) as string
            setVideoSnapURL(placeholder)
        }
        if (tracker.TimedMessages.length > 0) {
            fetchPlaceholder()
        }
    }, [race.id, tracker.TimedMessages.length])

    useEffect(() => {
        const processMessage = (event: MessageEvent) => {
            if (!event.data.videoSnapURL || !event.data.raceKey) {
                return
            }
            if (event.data.raceKey !== `${race.raceDate}-${race.trackCode}-${race.raceNumber}`) {
                return
            }
            setVideoSnapURL(event.data.videoSnapURL)
        }
        if (tracker.TimedMessages.length > 0) {
            window.addEventListener('message', processMessage)
        }
        return () => window.removeEventListener('message', processMessage)
    }, [race.id, tracker.TimedMessages.length])

    const classes = useStylesRaceVideo()
    const latency = useSelector((rootState: RootStateType) => rootState?.latencyStore?.latencyInSeconds ?? 0)

    const onDisplayedMessageChanged = async (message: string, secondsToPost: number) => {
        if (message !== '' && displayedMessage === '') {
            const videoBoundingRect = document?.getElementById(`${race.raceKey()}-video`)?.getBoundingClientRect()
            let x, y, width, height
            if (videoBoundingRect !== undefined) {
                x = videoBoundingRect.x / window.innerWidth
                y = videoBoundingRect.y / window.innerHeight
                width = videoBoundingRect.width / window.innerWidth
                height = videoBoundingRect.height / window.innerHeight
            }

            const type = `START_DISPLAY_${message.replace(/ /g, '_').toUpperCase()}`
            await createRaceState(
                {
                    apiURLOverride: tracker.ApiURLOverride,
                    raceDate: race.raceDate,
                    trackCode: race.trackCode,
                    raceNumber: race.raceNumber,
                    type: type,
                    latencyInSeconds: latency,
                    practiceMode: tracker.PracticeMode,
                    genericRealTimeTrackerID: tracker.ID,
                    x: x,
                    y: y,
                    width: width,
                    height: height,
                },
                dispatch
            )

            const typeWithTiming = `START_DISPLAY_${message
                .replace(/ /g, '_')
                .toUpperCase()}_STP_${secondsToPost.toFixed(1)}`
            await createRaceState(
                {
                    apiURLOverride: tracker.ApiURLOverride,
                    raceDate: race.raceDate,
                    trackCode: race.trackCode,
                    raceNumber: race.raceNumber,
                    type: typeWithTiming,
                    latencyInSeconds: latency,
                    practiceMode: tracker.PracticeMode,
                    genericRealTimeTrackerID: tracker.ID,
                },
                dispatch
            )
        }
        if (message === '' && displayedMessage !== '') {
            createRaceState(
                {
                    apiURLOverride: tracker.ApiURLOverride,
                    raceDate: race.raceDate,
                    trackCode: race.trackCode,
                    raceNumber: race.raceNumber,
                    type: `END_DISPLAY_${displayedMessage.replace(/ /g, '_').toUpperCase()}_STP_${secondsToPost.toFixed(
                        1
                    )}`,
                    latencyInSeconds: latency,
                    practiceMode: tracker.PracticeMode,
                    genericRealTimeTrackerID: tracker.ID,
                },
                dispatch
            )
        }
        setDisplayedMessage(message)
    }

    let videoOutlineClass = ''
    if (tracker?.TimedMessages?.length > 0) {
        videoOutlineClass = classes.invisibleOutline
    }
    if (displayedMessage !== '') {
        videoOutlineClass = classes.outline
    }

    return (
        <Grid container direction={'column'} alignItems={'center'} justifyContent={'center'} spacing={1}>
            {tracker.TimedMessages && tracker.TimedMessages.length > 0 && (
                <Grid>
                    <RealTimeTimedMessages
                        timedMessages={tracker.TimedMessages}
                        race={race}
                        setDisplayedMessage={onDisplayedMessageChanged}
                    />
                </Grid>
            )}
            <Grid item className={videoOutlineClass}>
                <VideoPlayerWithPictureSnapshot
                    url={videoUrl}
                    format={videoFormat}
                    idForIFrame={`${race.raceKey()}-video`}
                    pictureURL={videoSnapURL}
                    height={300}
                    width={400}
                    historical={false}
                    disableControls={true}
                />
            </Grid>
        </Grid>
    )
}

interface RealTimeTimedMessagesProps {
    timedMessages: GenericRealTimeTrackerTimedMessage[]
    race: Race
    setDisplayedMessage: (message: string, secondsToPost: number) => void
}

const RealTimeTimedMessages = ({ timedMessages, race, setDisplayedMessage }: RealTimeTimedMessagesProps) => {
    const [secondsToPost, setSecondsToPost] = useState(60.0)
    const [secondsToDisplayedMessage, setSecondsToDisplayedMessage] = useState(null as number | null)
    const dispatch = useDispatch()
    useEffect(() => {
        const timer = window.setInterval(async () => {
            try {
                const newSecondsToPost = await getSecondsToPostEstimate(race.raceDate, race.trackCode, race.raceNumber)
                const newSecondsToDisplayedMessage = calculatedSecondsToDisplayedMessage(
                    timedMessages,
                    race.trackCode,
                    newSecondsToPost
                )
                setSecondsToDisplayedMessage(newSecondsToDisplayedMessage)
                const displayedMessages = timedMessages.filter((timedMessage) =>
                    messageShouldDisplay(timedMessage, race.trackCode, newSecondsToPost)
                )
                const firstDisplayedMessage = displayedMessages.length > 0 ? displayedMessages[0].Message : ''
                setDisplayedMessage(firstDisplayedMessage, newSecondsToPost)
                setSecondsToPost(newSecondsToPost)
            } catch (e) {
                getErrorData(e, dispatch)
            }
        }, 1000)
        return () => clearInterval(timer)
    }, [race.raceDate, race.trackCode, race.raceNumber, dispatch, setDisplayedMessage, timedMessages])

    const displayedMessages = timedMessages.filter((timedMessage) =>
        messageShouldDisplay(timedMessage, race.trackCode, secondsToPost)
    )
    const classes = useStylesRaceVideo()

    return (
        <Grid container direction={'column'} spacing={1} justifyContent={'center'} alignItems={'center'}>
            {secondsToDisplayedMessage && displayedMessages.length === 0 && (
                <Grid item key={'seconds-to-display-message'}>
                    <Typography variant={'h6'} color={'error'} className={classes.alert}>
                        {Math.floor(secondsToDisplayedMessage)} seconds
                    </Typography>
                </Grid>
            )}
            {displayedMessages.length === 0 && !secondsToDisplayedMessage && (
                <Grid item className={classes.h6Height}></Grid>
            )}
            {displayedMessages.map((message) => (
                <Grid item key={message.Message}>
                    <Typography variant={'h6'} color={'error'} className={classes.alert}>
                        {message.Message}
                    </Typography>
                </Grid>
            ))}
        </Grid>
    )
}

function calculatedSecondsToDisplayedMessage(
    timedMessages: GenericRealTimeTrackerTimedMessage[],
    trackCode: string,
    newSecondsToPost: number
) {
    const secondsToDisplayByMessage = timedMessages.map((timedMessage) => {
        const extraOffsetSeconds =
            timedMessage.GenericRealTimeTrackerTimedMessageTrackOverrides?.find((x) => x.TrackCode === trackCode)
                ?.ExtraOffsetSeconds ?? 0
        const offsetSeconds = timedMessage.OffsetSeconds + extraOffsetSeconds
        const timedMessageStartSecondsBeforePost = -1 * offsetSeconds
        return newSecondsToPost - timedMessageStartSecondsBeforePost
    })
    return secondsToDisplayByMessage.find((secondsToDisplay) => 0 <= secondsToDisplay && secondsToDisplay <= 5) ?? null
}

function messageShouldDisplay(
    timedMessage: GenericRealTimeTrackerTimedMessage,
    trackCode: string,
    secondsToPost: number
) {
    const extraOffsetSeconds =
        timedMessage.GenericRealTimeTrackerTimedMessageTrackOverrides?.find((x) => x.TrackCode === trackCode)
            ?.ExtraOffsetSeconds ?? 0
    const offsetSeconds = timedMessage.OffsetSeconds + extraOffsetSeconds

    const timedMessageStartSecondsBeforePost = -1 * offsetSeconds

    const timedMessageEndSecondsBeforePost = -1 * (offsetSeconds + timedMessage.DurationSeconds)

    return secondsToPost < timedMessageStartSecondsBeforePost && secondsToPost > timedMessageEndSecondsBeforePost
}
