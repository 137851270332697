import React, { useEffect, useState } from 'react'
import { Grid, Paper, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Race } from '../../models/race'
import { getUpcomingRaces } from '../../services/get_upcoming_races'
import { useDispatch } from 'react-redux'
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab'
import { ArrowDropDown } from '@material-ui/icons'
import { RaceSpeedTest } from '../speed_test/RaceSpeedTest'

const useStyles = makeStyles((theme) => ({
    cardRoot: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
    header: {
        width: '100%',
        marginRight: 'auto',
    },
}))

const videoSources = [
    'xpressbet',
    'france_hd_fast',
    'world_wide_data',
    'world_wide_data_3',
    'wwd_france_trot',
    'wwd_france_gallop',
]

export const SpeedTest = () => {
    const classes = useStyles()
    const upcomingRaces = useUpcomingRaces()
    const [chosenRace, setChosenRace] = useState(null as Race | null)
    const [videoSource, setVideoSource] = useState(videoSources[0])
    return (
        <Paper className={classes.cardRoot}>
            <Grid container={true} direction={'column'} spacing={5}>
                {
                    <Grid item>
                        <Autocomplete
                            style={{ width: 350 }}
                            multiple={false}
                            id="race-selector"
                            options={upcomingRaces}
                            getOptionLabel={(race: Race) => race.raceKey()}
                            value={upcomingRaces.find((r) => r.raceKey() === chosenRace?.raceKey()) ?? null}
                            onChange={(event: any, race: any) => {
                                setChosenRace(race)
                            }}
                            renderInput={(params: AutocompleteRenderInputParams) => (
                                <TextField {...params} variant="standard" label="Race" placeholder="race" />
                            )}
                            renderOption={(race: Race) => <span>{race.raceKey()}</span>}
                            popupIcon={<ArrowDropDown />}
                        />
                    </Grid>
                }
                {
                    <Grid item>
                        <Autocomplete
                            style={{ width: 350 }}
                            multiple={false}
                            options={videoSources}
                            value={videoSource}
                            onChange={(event: any, source: string | null) => {
                                setVideoSource(source ?? 'xpressbet')
                            }}
                            renderInput={(params: AutocompleteRenderInputParams) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="video source"
                                    placeholder="xpressbet"
                                />
                            )}
                            popupIcon={<ArrowDropDown />}
                        />
                    </Grid>
                }
                {chosenRace && videoSource && (
                    <Grid item>
                        <RaceSpeedTest race={chosenRace} videoSource={videoSource} />
                    </Grid>
                )}
            </Grid>
        </Paper>
    )
}

const useUpcomingRaces = () => {
    const [upcomingRaces, setUpcomingRaces] = React.useState<Race[]>([])
    const dispatch = useDispatch()
    useEffect(() => {
        const fetchUpcomingRaces = async () => {
            const races = await getUpcomingRaces({
                mtp: 10,
                raceType: null,
                onlyAssigned: false,
                trackingType: '',
                country: 'USA,FRANCE,HK',
                dispatch: dispatch,
            })
            setUpcomingRaces(races)
        }
        fetchUpcomingRaces()
    }, [dispatch])
    return upcomingRaces
}
