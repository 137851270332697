import React, { useCallback } from 'react'
import { Grid, Typography } from '@material-ui/core'
import {
    GenericHistoricalTrackerRaceStateBase,
    GenericHistoricalTrackerRunnerEvent,
    GenericHistoricalTrackerRunnerEventGroup,
    GenericHistoricalTracker,
} from '../../services/generic_historical_tracker'
import { makeStyles, styled } from '@material-ui/core/styles'
import { addRunnerEvent } from '../../services/create_runner_event'
import { getSilkColor, getSilkColorsForRaceType } from '../../services/silk_colors'
import { useDispatch } from 'react-redux'
import { IRunner, IRunnerEvent } from '../../types'
import { Race } from '../../models/race'
import { JockeySilk } from '../common/JockeySilk'
import { ToggleButton } from '@material-ui/lab'

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
    border: 'none',
    color: theme.palette.text.primary,
    width: '100%',
    textTransform: 'none',
    '&.Mui-selected': {
        color: theme.palette.error.main,
    },
}))

const useStyles = makeStyles((theme) => ({
    slider: {
        height: 200,
    },
    runnerNumber: {
        width: '3rem',
        height: '3rem',
        cursor: 'pointer',
        textAlign: 'center',
        fontSize: '1.5rem !important',
    },
    jockeySilksImage: {
        width: '3rem',
        height: '3rem',
        cursor: 'pointer',
    },
    buttonContainer: {
        width: 600,
    },
    popupContainer: {
        padding: theme.spacing(1),
    },
    runnerName: {
        fontSize: '8pt',
        wordWrap: 'break-word',
        hyphens: 'auto',
        width: '4rem',
        height: '2.5rem',
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}))

export interface GenericTrackerSingleRunnerButtonsProps {
    race: Race
    videoTimestamp: number | null
    videoSource: string
    runner: IRunner
    tracker: GenericHistoricalTracker
    showJockeySilks: boolean
    showRunnerNames: boolean
    runnerButtonsFilter: string[]
    updateRace: () => void
}

export const GenericTrackerSingleRunnerButtonsIconList = ({
    tracker,
    race,
    runner,
    videoTimestamp,
    videoSource,
    showJockeySilks,
    showRunnerNames,
    updateRace,
    runnerButtonsFilter,
}: GenericTrackerSingleRunnerButtonsProps) => {
    const silkColors = getSilkColorsForRaceType(race.country, race.raceType)
    const trackerRaceStatePrefix = GenericHistoricalTrackerRaceStateBase(tracker)
    let buttonStyles: any
    if (getSilkColor(silkColors, runner.programNumber).backgroundImage) {
        buttonStyles = {
            backgroundImage: getSilkColor(silkColors, runner.programNumber).backgroundImage,
            color: 'transparent',
            backgroundSize: '100%',
            position: 'relative',
        }
    } else {
        buttonStyles = {
            color: getSilkColor(silkColors, runner.programNumber).color,
            backgroundColor: getSilkColor(silkColors, runner.programNumber).silkColor,
            position: 'relative',
        }
    }
    const classes = useStyles()
    const dispatch = useDispatch()

    const latestRunner = race.runners.find((r) => r.id === runner.id)!

    const buttonClickRunnerEvent = trackerRaceStatePrefix + '_RUNNER_CLICKED'
    const hasClickedButton = latestRunner.runnerEvents.some(
        (runnerEvent) => runnerEvent.type === buttonClickRunnerEvent
    )
    if (hasClickedButton) {
        buttonStyles = {
            ...buttonStyles,
            border: '3px black solid',
        }
    }

    const buttonsForThisRace = tracker.GenericHistoricalTrackerRunnerEvents.filter(
        (re) =>
            (re.RaceType === null || re.RaceType === race.raceType) &&
            (re.SubRaceType === null || re.SubRaceType === race.raceSubType) &&
            (re.StartType === null || re.StartType === race.startType) &&
            (runnerButtonsFilter.length === 0 || runnerButtonsFilter.some((f) => f == re.ShortTextButton))
    )

    const buttonGroupsForThisRace = tracker.GenericHistoricalTrackerRunnerEventGroups.filter(
        (re) =>
            (re.RaceType === null || re.RaceType === race.raceType) &&
            (re.SubRaceType === null || re.SubRaceType === race.raceSubType) &&
            (re.StartType === null || re.StartType === race.startType) &&
            (runnerButtonsFilter.length === 0 || runnerButtonsFilter.some((f) => f == re.Name))
    )

    const onClickRunnerButton = async () => {
        await addRunnerEvent(
            {
                programNumber: runner.programNumber,
                eventType: trackerRaceStatePrefix + '_RUNNER_CLICKED',
                raceDate: race.raceDate,
                trackCode: race.trackCode,
                raceNumber: race.raceNumber,
                practiceMode: tracker.PracticeMode,
                videoTimestamp: videoTimestamp,
                videoSource: videoSource,
                genericHistoricalTrackerID: tracker.ID,
            },
            dispatch
        )
        updateRace()
    }

    const onClickIconButton = useCallback(
        async (runnerEvent: GenericHistoricalTrackerRunnerEvent, alreadyHasEvent: boolean | undefined) => {
            let event = runnerEvent.RunnerEvent
            if (alreadyHasEvent) {
                event = 'REMOVE_' + event
            }
            await addRunnerEvent(
                {
                    eventType: event,
                    raceDate: race.raceDate,
                    trackCode: race.trackCode,
                    raceNumber: race.raceNumber,
                    programNumber: runner.programNumber,
                    practiceMode: tracker.PracticeMode,
                    videoTimestamp: videoTimestamp,
                    videoSource: videoSource,
                    genericHistoricalTrackerID: tracker.ID,
                },
                dispatch
            )
            updateRace()
        },
        [dispatch, race.id, runner, tracker.ID, tracker.PracticeMode, videoTimestamp, videoSource, updateRace]
    )

    const alreadyHasEvent = useCallback(
        (runnerEvent: GenericHistoricalTrackerRunnerEvent) => {
            const existingEvents = latestRunner?.runnerEvents?.filter(
                (existingRunnerEvents) =>
                    existingRunnerEvents.type === runnerEvent.RunnerEvent ||
                    existingRunnerEvents.type === 'REMOVE_' + runnerEvent.RunnerEvent
            )
            if (existingEvents) {
                existingEvents.sort((a, b) => b.id - a.id)
            }
            return existingEvents && existingEvents.length > 0 && existingEvents[0].type === runnerEvent.RunnerEvent
        },
        [latestRunner]
    )

    return (
        <Grid
            container
            direction={'column'}
            spacing={1}
            alignItems={'center'}
            className={`runner-buttons-${runner.programNumber}`}
        >
            <Grid item data-testid={`generic-tracker-number-${runner.programNumber}`} onClick={onClickRunnerButton}>
                <Grid container direction={'column'} spacing={1} alignItems={'center'}>
                    {showRunnerNames && (
                        <Grid item className={classes.runnerName}>
                            {runner.name}
                        </Grid>
                    )}
                    {showJockeySilks && (
                        <Grid item>
                            <JockeySilk race={race} runner={runner} />
                        </Grid>
                    )}
                    <Grid
                        item
                        style={buttonStyles}
                        className={`${classes.runnerNumber} generic-tracker-number-${runner.programNumber}`}
                    >
                        {runner.programNumber}
                        <span
                            style={{
                                position: 'absolute',
                                right: '3px',
                                bottom: 0,
                                fontSize: '8pt',
                                color: '#DDD',
                                display: runner.drawNumber > 0 ? 'inherit' : 'none',
                            }}
                        >
                            {runner.drawNumber}
                        </span>
                    </Grid>
                </Grid>
            </Grid>
            {tracker.GenericHistoricalTrackerRunnerEventGroups &&
                tracker.GenericHistoricalTrackerRunnerEventGroups.length > 0 &&
                buttonGroupsForThisRace.map((bg) => (
                    <SingleRunnerButtonsGroup
                        race={race}
                        runner={latestRunner}
                        buttonGroup={bg}
                        tracker={tracker}
                        videoTimestamp={videoTimestamp}
                        videoSource={videoSource}
                        updateRace={updateRace}
                    />
                ))}
            {buttonsForThisRace
                .sort((a, b) => a.Order - b.Order)
                .map((re) => {
                    const hasEvent = alreadyHasEvent(re)
                    return (
                        <Grid item key={re.RunnerEvent}>
                            <StyledToggleButton
                                name={re.ButtonText}
                                selected={hasEvent}
                                onChange={() => {
                                    onClickIconButton(re, hasEvent)
                                }}
                            >
                                <Typography>{re.ShortTextButton}</Typography>
                            </StyledToggleButton>
                        </Grid>
                    )
                })}
        </Grid>
    )
}

const isOneOfRunnerEvents = (runnerEvents: GenericHistoricalTrackerRunnerEvent[], desired: IRunnerEvent) => {
    return runnerEvents.some((re) => desired.type === re.RunnerEvent)
}
const isRemoveOneOfRunnerEvents = (runnerEvents: GenericHistoricalTrackerRunnerEvent[], desired: IRunnerEvent) => {
    return runnerEvents.some((re) => desired.type === 'REMOVE_' + re.RunnerEvent)
}

interface SingleRunnerButtonsGroupProps {
    race: Race
    runner: IRunner
    buttonGroup: GenericHistoricalTrackerRunnerEventGroup
    tracker: GenericHistoricalTracker
    videoTimestamp: number | null
    videoSource: string
    updateRace: () => void
}

const SingleRunnerButtonsGroup = ({
    race,
    runner,
    buttonGroup,
    tracker,
    videoTimestamp,
    videoSource,
    updateRace,
}: SingleRunnerButtonsGroupProps) => {
    const dispatch = useDispatch()
    const buttonsForThisRace = buttonGroup.GenericHistoricalTrackerRunnerEvents.filter(
        (re) =>
            (re.RaceType === null || re.RaceType === race.raceType) &&
            (re.SubRaceType === null || re.SubRaceType === race.raceSubType) &&
            (re.StartType === null || re.StartType === race.startType)
    )
    const onClickIconButton = useCallback(
        async (runnerEvent: GenericHistoricalTrackerRunnerEvent, alreadyHasEvent: boolean | undefined) => {
            let event = runnerEvent.RunnerEvent
            if (alreadyHasEvent) {
                event = 'REMOVE_' + event
            }
            await addRunnerEvent(
                {
                    programNumber: runner.programNumber,
                    eventType: event,
                    raceDate: race.raceDate,
                    trackCode: race.trackCode,
                    raceNumber: race.raceNumber,
                    practiceMode: tracker.PracticeMode,
                    videoTimestamp: videoTimestamp,
                    videoSource: videoSource,
                    genericHistoricalTrackerID: tracker.ID,
                },
                dispatch
            )
            updateRace()
        },
        [dispatch, race.id, runner, tracker.ID, tracker.PracticeMode, updateRace, videoTimestamp, videoSource]
    )

    const isSelected = useCallback(
        (runnerEvent: GenericHistoricalTrackerRunnerEvent) => {
            const existingEvents = runner?.runnerEvents?.filter(
                (existingRunnerEvent) =>
                    isOneOfRunnerEvents(buttonsForThisRace, existingRunnerEvent) ||
                    isRemoveOneOfRunnerEvents(buttonsForThisRace, existingRunnerEvent)
            )
            if (existingEvents) {
                existingEvents.sort((a, b) => b.id - a.id)
            }
            return existingEvents && existingEvents.length > 0 && existingEvents[0].type === runnerEvent.RunnerEvent
        },
        [runner, buttonsForThisRace]
    )

    if (!buttonsForThisRace?.length) {
        return null
    }

    return (
        <>
            {buttonsForThisRace
                .sort((a, b) => a.Order - b.Order)
                .map((re) => {
                    const hasEvent = isSelected(re)
                    return (
                        <Grid item key={re.RunnerEvent}>
                            <StyledToggleButton
                                name={re.ButtonText}
                                selected={hasEvent}
                                onChange={() => {
                                    onClickIconButton(re, hasEvent)
                                }}
                            >
                                <Typography>{re.ShortTextButton}</Typography>
                            </StyledToggleButton>
                        </Grid>
                    )
                })}
        </>
    )
}
