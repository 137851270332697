import React, { useCallback, useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles, styled } from '@material-ui/core/styles'
import { addRunnerEvent } from '../../services/create_runner_event'
import { getSilkColorsForRaceType, getSilkColor } from '../../services/silk_colors'
import { useDispatch } from 'react-redux'
import {
    GenericRealTimeTracker,
    GenericRealTimeTrackerRecall,
    GenericRealTimeTrackerRunnerEvent,
    GenericRealTimeTrackerRunnerEventGroup,
} from '../../services/generic_real_time_tracker'
import { IRunner, IRunnerEvent } from '../../types'
import { Race } from '../../models/race'
import { JockeySilk } from '../common/JockeySilk'
import { hasRaceState } from './RaceButtons'
import { ToggleButton } from '@material-ui/lab'

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
    border: 'none',
    color: theme.palette.text.primary,
    width: '100%',
    textTransform: 'none',
    '&.Mui-selected': {
        color: theme.palette.error.main,
    },
}))

const useStyles = makeStyles((theme) => ({
    slider: {
        height: 200,
    },
    runnerNumber: {
        width: '3rem',
        height: '3rem',
        cursor: 'pointer',
        textAlign: 'center',
        fontSize: '1.5rem !important',
    },
    buttonContainer: {
        width: 600,
    },
    popupContainer: {
        padding: theme.spacing(1),
    },
    jockeySilksImage: {
        width: '3rem',
        height: '3rem',
        cursor: 'pointer',
    },
    runnerName: {
        fontSize: '8pt',
        wordWrap: 'break-word',
        hyphens: 'auto',
        width: '4rem',
        height: '2.5rem',
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}))

export interface GenericTrackerSingleRunnerButtonsProps {
    race: Race
    runner: IRunner
    tracker: GenericRealTimeTracker
    videoSource: string
    showJockeySilks: boolean
    showRunnerNames: boolean
    updateUpcoming: () => void
    runnerButtonFilter: string[]
}

export const GenericTrackerSingleRunnerButtonsIconList = ({
    tracker,
    race,
    runner,
    videoSource,
    showJockeySilks,
    showRunnerNames,
    updateUpcoming,
    runnerButtonFilter,
}: GenericTrackerSingleRunnerButtonsProps) => {
    const [hasClickedButton, setHasClickedButton] = useState(false)
    const [iconButtonsClicked, setIconButtonsClicked] = useState<string[]>([])
    const silkColors = getSilkColorsForRaceType(race.country, race.raceType)
    let buttonStyles: any
    if (getSilkColor(silkColors, runner.programNumber).backgroundImage) {
        buttonStyles = {
            backgroundImage: getSilkColor(silkColors, runner.programNumber).backgroundImage,
            color: 'transparent',
            backgroundSize: '100%',
            position: 'relative',
        }
    } else {
        buttonStyles = {
            color: getSilkColor(silkColors, runner.programNumber).color,
            backgroundColor: getSilkColor(silkColors, runner.programNumber).silkColor,
            position: 'relative',
        }
    }
    const dispatch = useDispatch()
    const classes = useStyles()

    useEffect(() => {
        if (!tracker.GenericRealTimeTrackerRunnerEvents) {
            return
        }
        const buttonsClicked = tracker.GenericRealTimeTrackerRunnerEvents.filter((runnerEvent) =>
            clickedButton(runnerEvent, runner)
        ).map((runnerEvent) => runnerEvent.RunnerEvent)
        setIconButtonsClicked(buttonsClicked)
    }, [])

    useEffect(() => {
        if (!hasRaceState(race.raceStates, GenericRealTimeTrackerRecall(tracker))) {
            return
        }
        const buttonClickRunnerEvent = tracker.RunnerButtonsEventType
        const calculatedHasClickedRunner = runner.runnerEvents.some(
            (runnerEvent) => runnerEvent.type === buttonClickRunnerEvent
        )
        setHasClickedButton(calculatedHasClickedRunner)
    }, [race, runner, tracker])

    if (hasClickedButton) {
        buttonStyles = {
            ...buttonStyles,
            border: '3px black solid',
        }
    }

    const buttonsForThisRace = tracker.GenericRealTimeTrackerRunnerEvents.filter(
        (re) =>
            (re.RaceType === null || re.RaceType === race.raceType) &&
            (re.SubRaceType === null || re.SubRaceType === race.raceSubType) &&
            (re.StartType === null || re.StartType === race.startType) &&
            (runnerButtonFilter.length === 0 || runnerButtonFilter.some((rbf) => rbf === re.ShortTextButton))
    )

    const buttonGroupsForThisRace = tracker.GenericRealTimeTrackerRunnerEventGroups.filter(
        (re) =>
            (re.RaceType === null || re.RaceType === race.raceType) &&
            (re.SubRaceType === null || re.SubRaceType === race.raceSubType) &&
            (re.StartType === null || re.StartType === race.startType) &&
            (runnerButtonFilter.length === 0 || runnerButtonFilter.some((rbf) => rbf === re.Name))
    )

    const onClickRunnerButton = useCallback(async () => {
        setHasClickedButton(true)
        await addRunnerEvent(
            {
                apiURLOverride: tracker.ApiURLOverride,
                programNumber: runner.programNumber,
                eventType: tracker.RunnerButtonsEventType,
                raceDate: race.raceDate,
                trackCode: race.trackCode,
                raceNumber: race.raceNumber,
                practiceMode: tracker.PracticeMode,
                videoTimestamp: null,
                videoSource: videoSource,
                genericRealTimeTrackerID: tracker.ID,
            },
            dispatch
        )
        updateUpcoming()
    }, [runner, tracker, race, dispatch, updateUpcoming, setHasClickedButton, videoSource])

    const onClickIconButton = useCallback(
        async (runnerEvent: GenericRealTimeTrackerRunnerEvent) => {
            let event = runnerEvent.RunnerEvent
            if (iconButtonsClicked.includes(event)) {
                event = 'REMOVE_' + event
                setIconButtonsClicked(iconButtonsClicked.filter((clicked) => clicked !== runnerEvent.RunnerEvent))
            } else {
                setIconButtonsClicked([...iconButtonsClicked, runnerEvent.RunnerEvent])
            }
            await addRunnerEvent(
                {
                    apiURLOverride: tracker.ApiURLOverride,
                    programNumber: runner.programNumber,
                    eventType: event,
                    raceDate: race.raceDate,
                    trackCode: race.trackCode,
                    raceNumber: race.raceNumber,
                    practiceMode: tracker.PracticeMode,
                    videoTimestamp: null,
                    videoSource: videoSource,
                    genericRealTimeTrackerID: tracker.ID,
                },
                dispatch
            )
            updateUpcoming()
        },
        [dispatch, race.id, runner, tracker.ID, tracker.PracticeMode, updateUpcoming, videoSource, iconButtonsClicked]
    )

    return (
        <Grid
            container
            direction={'column'}
            spacing={1}
            alignItems={'center'}
            className={`runner-buttons-${runner.programNumber}`}
        >
            <Grid item data-testid={`generic-tracker-number-${runner.programNumber}`} onClick={onClickRunnerButton}>
                <Grid container direction={'column'} spacing={1} alignItems={'center'}>
                    {showRunnerNames && (
                        <Grid item className={classes.runnerName}>
                            {runner.name}
                        </Grid>
                    )}
                    {showJockeySilks && (
                        <Grid item>
                            <JockeySilk race={race} runner={runner} />
                        </Grid>
                    )}
                    <Grid
                        item
                        style={buttonStyles}
                        className={`${classes.runnerNumber} generic-tracker-number-${runner.programNumber}`}
                    >
                        {runner.programNumber}
                        <span
                            style={{
                                position: 'absolute',
                                right: '3px',
                                bottom: 0,
                                fontSize: '8pt',
                                color: '#DDD',
                                display: runner.drawNumber > 0 ? 'inherit' : 'none',
                            }}
                        >
                            {runner.drawNumber}
                        </span>
                    </Grid>
                </Grid>
            </Grid>
            {tracker.GenericRealTimeTrackerRunnerEventGroups &&
                tracker.GenericRealTimeTrackerRunnerEventGroups.length > 0 &&
                buttonGroupsForThisRace.map((bg) => (
                    <SingleRunnerButtonsGroup
                        race={race}
                        runner={runner}
                        videoSource={videoSource}
                        buttonGroup={bg}
                        tracker={tracker}
                        updateUpcoming={updateUpcoming}
                    />
                ))}
            {buttonsForThisRace
                .sort((a, b) => a.Order - b.Order)
                .map((re) => {
                    return (
                        <Grid item key={re.RunnerEvent}>
                            <StyledToggleButton
                                name={re.ButtonText}
                                selected={iconButtonsClicked.includes(re.RunnerEvent)}
                                onChange={() => {
                                    onClickIconButton(re)
                                }}
                            >
                                <Typography>{re.ShortTextButton}</Typography>
                            </StyledToggleButton>
                        </Grid>
                    )
                })}
        </Grid>
    )
}

const isOneOfRunnerEvents = (runnerEvents: GenericRealTimeTrackerRunnerEvent[], desired: IRunnerEvent) => {
    return runnerEvents.some((re) => desired.type === re.RunnerEvent)
}
const isRemoveOneOfRunnerEvents = (runnerEvents: GenericRealTimeTrackerRunnerEvent[], desired: IRunnerEvent) => {
    return runnerEvents.some((re) => desired.type === 'REMOVE_' + re.RunnerEvent)
}
const calculateLastRunnerEventClicked = (buttonsForThisRace: GenericRealTimeTrackerRunnerEvent[], runner: IRunner) => {
    const existingEvents = runner?.runnerEvents?.filter(
        (existingRunnerEvent) =>
            isOneOfRunnerEvents(buttonsForThisRace, existingRunnerEvent) ||
            isRemoveOneOfRunnerEvents(buttonsForThisRace, existingRunnerEvent)
    )
    if (existingEvents) {
        existingEvents.sort((a, b) => b.id - a.id)
    }
    if (existingEvents && existingEvents.length > 0) {
        return existingEvents[0].type
    }
    return null
}

interface SingleRunnerButtonsGroupProps {
    race: Race
    runner: IRunner
    videoSource: string
    buttonGroup: GenericRealTimeTrackerRunnerEventGroup
    tracker: GenericRealTimeTracker
    updateUpcoming: () => void
}

const SingleRunnerButtonsGroup = ({
    race,
    runner,
    videoSource,
    buttonGroup,
    tracker,
    updateUpcoming,
}: SingleRunnerButtonsGroupProps) => {
    const dispatch = useDispatch()
    const [currentButtonClicked, setCurrentButtonClicked] = useState<string | null>(null)
    const buttonsForThisRace = buttonGroup.GenericRealTimeTrackerRunnerEvents.filter(
        (re) =>
            (re.RaceType === null || re.RaceType === race.raceType) &&
            (re.SubRaceType === null || re.SubRaceType === race.raceSubType) &&
            (re.StartType === null || re.StartType === race.startType)
    )
    useEffect(() => {
        const lastRunnerEventClicked = calculateLastRunnerEventClicked(buttonsForThisRace, runner)
        setCurrentButtonClicked(lastRunnerEventClicked)
    }, [])
    const onClickIconButton = useCallback(
        async (runnerEvent: GenericRealTimeTrackerRunnerEvent) => {
            let event = runnerEvent.RunnerEvent
            if (runnerEvent.RunnerEvent === currentButtonClicked) {
                event = 'REMOVE_' + event
                setCurrentButtonClicked(null)
            } else {
                setCurrentButtonClicked(event)
            }
            await addRunnerEvent(
                {
                    apiURLOverride: tracker.ApiURLOverride,
                    programNumber: runner.programNumber,
                    eventType: event,
                    raceDate: race.raceDate,
                    trackCode: race.trackCode,
                    raceNumber: race.raceNumber,
                    practiceMode: tracker.PracticeMode,
                    videoTimestamp: null,
                    videoSource: videoSource,
                    genericRealTimeTrackerID: tracker.ID,
                },
                dispatch
            )
            updateUpcoming()
        },
        [dispatch, race.id, runner, tracker.ID, tracker.PracticeMode, updateUpcoming, videoSource]
    )

    if (!buttonsForThisRace?.length) {
        return null
    }

    return (
        <>
            {buttonsForThisRace
                .sort((a, b) => a.Order - b.Order)
                .map((re) => {
                    return (
                        <Grid item key={re.RunnerEvent}>
                            <StyledToggleButton
                                name={re.ButtonText}
                                selected={re.RunnerEvent === currentButtonClicked}
                                onChange={() => {
                                    onClickIconButton(re)
                                }}
                            >
                                <Typography>{re.ShortTextButton}</Typography>
                            </StyledToggleButton>
                        </Grid>
                    )
                })}
        </>
    )
}

const clickedButton = (runnerEvent: GenericRealTimeTrackerRunnerEvent, runner: IRunner) => {
    const existingEvents = runner?.runnerEvents?.filter(
        (existingRunnerEvents) =>
            existingRunnerEvents.type === runnerEvent.RunnerEvent ||
            existingRunnerEvents.type === 'REMOVE_' + runnerEvent.RunnerEvent
    )
    if (existingEvents) {
        existingEvents.sort((a, b) => b.id - a.id)
    }
    return existingEvents && existingEvents.length > 0 && existingEvents[0].type === runnerEvent.RunnerEvent
}
