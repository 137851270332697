import { IUser } from '../types/user'

export class User {
    public id: number
    public userName: string
    public active: boolean
    public team: string
    public betaReleaseChannel: boolean

    constructor(userJson: IUser) {
        this.id = userJson.id
        this.userName = userJson.user_name
        this.team = userJson.team
        this.active = userJson.active
        this.betaReleaseChannel = userJson.beta_release_channel
    }
}
